import React from 'react'
import Layout from '../../components/Layout'
import H1 from '../../components/H1'

export default function Page() {
  return (
    <Layout>
      <H1 title="Membership" />
      <h2 id="introduction">INTRODUCTION</h2>

      <p>
        The Alabama Association for Institutional Research (ALAIR) is an
        organization offering a variety of Professional opportunities for
        individuals involved in institutional research or related activities in
        Alabama's institutions of higher education. ALAIR is affiliated with the
        Southern Association for Institutional Research (SAIR) and the
        Association for Institutional Research (AIR).
      </p>

      <p>
        <a href="/forms/ALAIR Membership Registration Form 2023-Fillable.pdf" target="_blank">
          Download Membership Application
        </a>
      </p>

      <h2 id="membership">MEMBERSHIP</h2>

      <p>
        Membership in ALAIR is open to any person who is actively engaged in
        institutional research and related functions or who has professional
        interest in activities related to institutional research including
        assessment, planning, student records, etc.
      </p>

      <h2 id="purpose">PURPOSE</h2>

      <p>ALAIR has three major purposes:</p>

      <ul>
        <li>
          The advancement of institutional research leading to improved
          understanding, planning and operation of Alabama institutions of
          higher education
        </li>

        <li>
          The dissemination of information and interchange of ideas on problems
          of common interest.
        </li>

        <li>The continued professional development of ALAIR members.</li>
      </ul>

      <h2 id="benefits">BENEFITS</h2>

      <p>Professional Development through the following:</p>

      <ul>
        <li>Meeting that focus on topics of current interest to members.</li>

        <li>
          Opportunities to discuss special topics and exchange ideas with
          colleagues.
        </li>

        <li>
          Correspondence, including a newsletter with information on meeting,
          topics of current interest, proceedings of past meeting and news
          information on Alabama membership.
        </li>
      </ul>

      <h2 id="meetings">MEETINGS</h2>

      <p>
        ALAIR meets once each year. The annual business meeting is held in
        conjunction with the conference. The Association also meets during the
        SAIR and AIR affiliated group meeting sessions.
      </p>
    </Layout>
  )
}
